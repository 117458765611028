import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { VenuesGrid } from "./VenuesGrid";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

const VenuesView = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selected, setSelected] = React.useState<readonly any[]>([]);
  const { token, setToken } = useToken();

  async function getRestaurants() {
    const url = new URL(`${Base_Url}/venues`);
    const options = {
      method: "GET",
      headers: new Headers({
          Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data) {
          setRestaurants(data.restaurants);
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getRestaurants();
  }, []);
  //   }, [token]);

  const removeRestaurants = async () => {
    if (!selected || selected.length == 0) return;
    return fetch(`${Base_Url}/venues`, {
      method: "DELETE",
      mode: "cors",
      credentials: "same-origin",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        restaurants: selected.map((restaurant) => restaurant.id),
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (!data.success) {
          throw data.errorMessage;
        }
        setRestaurants(restaurants.filter(restaurant => !selected.includes(restaurant)));
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (restaurants) {
    return (
      <div className="restaurantsGridWrapper">
        <div className="button">
          <Button variant="primary" onClick={getRestaurants}>
            Refresh
          </Button>
          <Button variant="primary" onClick={removeRestaurants}>
            Remove
          </Button>
        </div>
        <VenuesGrid
          restaurants={restaurants}
          selected={selected}
          setSelected={setSelected}
        ></VenuesGrid>
      </div>
    );
  }

  return (
    <div className="restaurantsGridWrapper">
      <div className="emptyPortalResturantsGrid">Empty</div>
    </div>
  );
};

export default VenuesView;
