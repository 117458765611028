import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { UserNameView } from "./UserNameView";
import UserImagesView from "../UserProfile/UserImagesView";
import IdCellButton from "../components/IdCellButton";

interface UserGridProps {
  users: any[];
  selected: readonly any[];
  setSelected: (selected: readonly any[]) => void;
  page: number;
  rowsPerPage: number;
  totalUsers: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const UserGrid = (props: UserGridProps) => {
  const {
    users,
    selected,
    setSelected,
    page,
    rowsPerPage,
    totalUsers,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  const [sortField, setSortField] = useState("");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, user: any) => {
    const selectedIndex = selected.indexOf(user);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRowClick = (userId: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(userId)) {
      newExpandedRows.delete(userId);
    } else {
      newExpandedRows.add(userId);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleRequestSort = (property: any) => {
    const isAsc = sortField === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setSortField(property);
  };

  const renderPromptData = (promptData: any, userId: string) => {
    if (expandedRows.has(userId)) {
      return Object.keys(promptData)
        .filter((key) => promptData[key])
        .map((key) => <p key={key}>{promptData[key].promptValue}</p>);
    } else {
      const nonEmptyPrompts = Object.keys(promptData).filter(
        (key) => promptData[key]?.promptValue?.trim() !== ""
      );

      return <p>{nonEmptyPrompts.length}</p>;
    }
  };

  const isSelected = (user: any) => selected.indexOf(user) !== -1;

  const sortedUsers = useMemo(() => {
    return [...users].sort((a: any, b: any) => {
      if (sortField) {
        if (
          sortField === "active_venues" ||
          sortField === "active_events" ||
          sortField === "likes" ||
          sortField === "dislikes"
        ) {
          return orderDirection === "asc"
            ? a[sortField].length - b[sortField].length
            : b[sortField].length - a[sortField].length;
        }
      }
      return 0;
    });
  }, [users, sortField, orderDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="user table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={
                  selected.length > 0 && selected.length < users.length
                }
                checked={users.length > 0 && selected.length === users.length}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all users" }}
              />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Target Gender</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "active_venues"}
                direction={orderDirection}
                onClick={() => handleRequestSort("active_venues")}
              >
                Venues
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "active_events"}
                direction={orderDirection}
                onClick={() => handleRequestSort("active_events")}
              >
                Events
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "likes"}
                direction={orderDirection}
                onClick={() => handleRequestSort("likes")}
              >
                Likes
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "dislikes"}
                direction={orderDirection}
                onClick={() => handleRequestSort("dislikes")}
              >
                Dislikes
              </TableSortLabel>
            </TableCell>
            <TableCell>Blocked</TableCell>
            <TableCell>Prompts</TableCell>
            <TableCell>Education</TableCell>
            <TableCell>Occupation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.map((user: any, index: number) => (
            <React.Fragment key={user.id}>
              <TableRow
                hover
                role="checkbox"
                selected={isSelected(user)}
                onClick={(event) => [
                  handleClick(event, user),
                  handleRowClick(user.id),
                ]}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isSelected(user)}
                    inputProps={{
                      "aria-labelledby": `user-checkbox-${index}`,
                    }}
                  />
                </TableCell>
                <IdCellButton id={user.id} />
                <TableCell>
                  <UserNameView selectedUser={user} />
                </TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{user.gender}</TableCell>
                <TableCell>{user.targetGenders.join(", ")}</TableCell>
                <TableCell>{user.active_venues?.length || 0}</TableCell>
                <TableCell>{user.active_events?.length || 0}</TableCell>
                <TableCell>{user.likes?.length || 0}</TableCell>
                <TableCell>{user.dislikes?.length || 0}</TableCell>
                <TableCell>{user.blocked?.length || 0}</TableCell>
                <TableCell>
                  {renderPromptData(user.profileData.prompts, user.id)}
                </TableCell>
                <TableCell>{user.profileData.education || "N/A"}</TableCell>
                <TableCell>{user.profileData.occupation || "N/A"}</TableCell>
              </TableRow>
              {expandedRows.has(user.id) && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <UserImagesView imageUrls={user.imageUrls} />{" "}
                    {/* Replace this with the correct prop */}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
