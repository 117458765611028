import React, { useEffect } from "react";
import Login from "../Login/Login";
import PortalHome from "../Home/HomeView";
import useToken from "../Login/useToken";
import "./Portal.css";
import { TabProvider } from "../../context/TabContext";

function Portal() {
  const { token, setToken } = useToken();
  return token ? (
    <TabProvider>
      <PortalHome />
    </TabProvider>
  ) : (
    <Login setToken={setToken} />
  );
}

export default Portal;
