import React, { useState } from "react";
import { useUserInfoViewData } from "../../context/UserInfoContext";
import { UserInfo } from "./UserInfoView";

const UserProfile = () => {
  const { user, setUser } = useUserInfoViewData();

  return user != null ? (
    <div>
      <UserInfo user={user} />
    </div>
  ) : (
    <div>No user selected</div>
  );
};

export default UserProfile;
