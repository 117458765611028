import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, TableSortLabel, Checkbox } from "@mui/material";

interface EventsGridProps {
  events: any[];
  selected: readonly any[];
  setSelected: any;
}

export const EventsGridView = (props: EventsGridProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selected, setSelected } = props;
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");

  const events = props.events || [];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - events.length) : 0;

  const handleEventRequestSort = () => {
    const isAsc = orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    sortEvents(isAsc ? "desc" : "asc");
  };

  const sortEvents = (direction: "asc" | "desc") => {
    return events.sort((a, b) => {
      if (direction === "asc") return a.users?.length - b.users?.length;
      else return b.users?.length - a.users?.length;
    });
  };

  const sortedEvents = React.useMemo(() => {
    return sortEvents(orderDirection);
  }, [events, orderDirection]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = events.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, eventItem: any) => {
    const selectedIndex = selected.indexOf(eventItem);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, eventItem);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (eventItem: any) => selected.indexOf(eventItem) !== -1;

  return (
    <div className="eventsGridWrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow key="header">
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selected.length > 0 && selected.length < events.length
                  }
                  checked={
                    events.length > 0 &&
                    selected.length === events.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all events",
                  }}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Location</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Artists</TableCell>
              <TableCell align="left" sortDirection={orderDirection}>
                <TableSortLabel
                  active={true}
                  direction={orderDirection}
                  onClick={handleEventRequestSort}
                >
                  Num Users
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? events.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : events
            ).map((eventItem: any, index: any) => {
              const isItemSelected = isSelected(eventItem);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={eventItem.id}
                  role="checkbox"
                  onClick={(event) => handleClick(event, eventItem)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <a href={eventItem.websiteUri} target="_blank">
                      {eventItem.name}
                    </a>
                  </TableCell>
                  <TableCell align="left">{eventItem.date}</TableCell>
                  <TableCell align="left">
                    {typeof eventItem.location === 'object' 
                      ? `${eventItem.location.latitude}, ${eventItem.location.longitude}` 
                      : eventItem.location}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {eventItem.price}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {eventItem.artists}
                  </TableCell>
                  <TableCell align="left" sortDirection={orderDirection}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection}
                      onClick={handleEventRequestSort}
                    >
                      Num Users
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={3}
                count={events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

