import { useEffect, useState } from "react";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { format, parse } from "date-fns";

export const MatchMessagesView = (props: { match: any }) => {
  let match = props.match;

  const { token } = useToken();
  const [messages, setMessages] = useState<any[]>([]);

  async function getMessages() {
    const matchId = match.id;
    if (!matchId) {
      console.error("no match id");
      return;
    }

    const url = new URL(`${Base_Url}/matches/match/messages`);
    url.searchParams.append("matchId", matchId);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };

    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data) {
          setMessages(data.messages);
        } else {
          throw data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getUserName(userId: string) {
    let user1 = match.users[0];
    let user2 = match.users[1];

    if (!user1 || !user2) return "";

    if (userId === user1?.id) return user1?.firstName;
    if (userId === user2?.id) return user2.firstName;
  }

  useEffect(() => {
    getMessages();
  }, [match]);

  const formatDateTime = (date: string, time: string) => {
    // Check if time includes AM/PM
    let timeFormatted = time;

    // Convert time to 24-hour format if it is in 12-hour format (e.g., "10:00 PM")
    if (/[AP]M/i.test(time)) {
      // Create a Date object to standardize the time format
      const parsedTime = parse(time, "h:mm a", new Date());
      timeFormatted = format(parsedTime, "HH:mm:ss");
    }

    // Combine date and formatted time
    const dateTimeString = `${date}T${timeFormatted}`;
    const dateTime = new Date(dateTimeString);

    // Format the Date object
    return format(dateTime, "MMM d, yyyy h:mm a");
  };

  const formatTimestamp = (timestamp: { _seconds: number }) => {
    return format(new Date(timestamp._seconds * 1000), "MMM d, yyyy h:mm a");
  };

  const getLocationName = (id: any) => {
    return match.venues[id]?.venueName;
  };

  const renderMessageContent = (message: any) => {
    if (message.location && message.time && message.state) {
      // If the message contains location, time, and state, display them as a "date" message
      return (
        <Box>
          <Typography variant="body1">
            {formatDateTime(message.date, message.time)}
          </Typography>
          <Typography variant="body2">
            {getLocationName(message.location)}
          </Typography>
          <Typography variant="body2">State: {message.state}</Typography>
        </Box>
      );
    } else {
      // Otherwise, it's a normal message
      return <Typography variant="body1">{message.messageText}</Typography>;
    }
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h6" gutterBottom>
        Chat Messages
      </Typography>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <List>
          {messages.map((message) => (
            <ListItem
              key={message.id}
              sx={{
                display: "flex",
                flexDirection:
                  message.sentBy === match.users[0]?.id ? "row-reverse" : "row",
              }}
            >
              <Avatar>{getUserName(message.sentBy)}</Avatar>
              <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                <Paper
                  elevation={1}
                  sx={{
                    padding: 1,
                    backgroundColor:
                      message.sentBy === match.users[0]?.id
                        ? "#d1e7dd"
                        : "#f1f1f1",
                  }}
                >
                  <ListItemText
                    primary={renderMessageContent(message)}
                    secondary={formatTimestamp(message.sentAt)}
                  />
                </Paper>
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};
