import React, { createContext, useContext, useState } from 'react';

interface TabData {
    tab: string;
    setTab: (tab: string) => any
}

const defaultValue: TabData = {
    tab: "resturants", 
    setTab: () => {},
}

const TabContext = createContext<TabData>(defaultValue);

// Context provider component
export const TabProvider = ({ children }: any) => {
  const [tab, setTab] = useState("resturants"); // Hold the data in state

  // The value that will be supplied to any descendants of this provider
  const value = { tab, setTab };

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

// Custom hook to use the context
export const useTabData = () => useContext(TabContext);