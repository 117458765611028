import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  TableHead,
  Tooltip,
  Button,
} from "@mui/material";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import { UserNameView } from "../UsersManager/UserNameView";
import { MatchMessagesView } from "../Matches/MatchMessagesView";
import IdCellButton from "../components/IdCellButton";

export const MatchesView = () => {
  const { token } = useToken();
  const [matches, setMatches] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastVisibleId, setLastVisibleId] = useState<string | null>(null);
  const [totalMatches, setTotalMatches] = useState(0);

  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchMatches = async () => {
      const url = new URL(`${Base_Url}/matches`);
      url.searchParams.append("pageSize", rowsPerPage.toString());
      if (lastVisibleId) {
        url.searchParams.append("lastVisibleId", lastVisibleId);
      }

      const options = {
        method: "GET",
        headers: new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        }),
      };

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          setMatches(data.matches || []);
          setLastVisibleId(data.lastVisibleId);
          setTotalMatches(data.totalMatches);
        } else {
          throw new Error("Failed to fetch matches");
        }
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };

    fetchMatches();
  }, [token, page, rowsPerPage]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onRowClick = (id: string) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="matches table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Users</TableCell>
            <TableCell>Recent Message</TableCell>
            <TableCell>Venue Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Request</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map((match: any) => {
            const isRowExpanded = expandedRows.has(match.id);

            return (
              <React.Fragment key={match.id}>
                <TableRow key={match.id} onClick={() => onRowClick(match.id)}>
                  <IdCellButton id={match.id} />
                  <TableCell>
                    {match.users[0] != null && match.users[1] != null && (
                      <div>
                        <UserNameView selectedUser={match.users[0]} />
                        <UserNameView selectedUser={match.users[1]} />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <p>{match.recentMessage?.messageText || "No message"}</p>
                  </TableCell>
                  <TableCell>
                    {Object.keys(match.venues)?.length || "N/A"}
                  </TableCell>
                  <TableCell>
                    {match.createdAt && match.createdAt._seconds
                      ? new Date(
                          match.createdAt._seconds * 1000 +
                            match.createdAt._nanoseconds / 1000000
                        ).toLocaleString()
                      : "N/A"}{" "}
                    {/* or handle missing date */}
                  </TableCell>
                  <TableCell>
                    {match.request?.state ?? "None"}
                  </TableCell>
                </TableRow>
                {isRowExpanded && match.recentMessage?.messageText != null && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <MatchMessagesView match={match} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={totalMatches}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
