import React from "react";

interface UserImagesViewProps {
  imageUrls: { [key: string]: string };
  height?: string; // Optional height prop
}

export const UserImagesView: React.FC<UserImagesViewProps> = ({
  imageUrls,
  height = "33vh", // Default to 33vh if no height is provided
}) => {
  return (
    <div
      className="UserImagesViewWrapper"
      style={{
        display: "flex",
        overflowX: "scroll",
        overflowY: "scroll",
        maxHeight: height, // Use the height prop for max height
        whiteSpace: "nowrap", // Ensure items stay in a single line
        padding: 0, // Remove any padding in the container
      }}
    >
      {Object.entries(imageUrls).map(([key, url]: any) => (
        <div key={key} style={{ margin: 0 }}>
          <img
            src={url}
            alt={key}
            style={{
              height: height, // Use the height prop for image height
              width: "auto", // Automatically adjust width to maintain aspect ratio
              display: "block", // Ensure no spacing due to inline elements
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default UserImagesView;
