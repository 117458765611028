import React, { useState } from "react";
import { TableCell, Tooltip, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function IdCellButton({ id }: any) {
  const [hovered, setHovered] = useState(false);

  const handleCopyToClipboard = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stop event propagation
    navigator.clipboard.writeText(id);
  };

  return (
    <TableCell
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Tooltip title={id} open={hovered}>
        <Button onClick={handleCopyToClipboard} variant="text" size="small">
          <ContentCopyIcon />
        </Button>
      </Tooltip>
    </TableCell>
  );
}

export default IdCellButton;
