import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { UserGrid } from "./UserGrid";
import { UsersDistributionDashboard } from "./UsersDistributionDashboard";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

const filterOutEmptyProfiles = (users: any) => {
  if (users && users.length > 0) {
    return users.filter(
      (user: any) => user.firstName != null && user.firstName !== ""
    );
  }
};

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState<readonly any[]>([]);
  const { token } = useToken();
  
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // For backend pagination (0-based index)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [totalUsers, setTotalUsers] = useState(0); // Total users count from backend

  async function getUsers(page = 0, limit = 10) {
    setLoading(true);
    const url = new URL(`${Base_Url}/users`);
    url.searchParams.append("page", (page + 1).toString()); // Backend expects 1-based index
    url.searchParams.append("limit", limit.toString());

    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data) {
          setUsers(filterOutEmptyProfiles(data.users));
          setTotalUsers(data.total); // Set total user count from the backend response
        } else {
          throw new Error(data.errorMessage);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  // Fetch users on initial render and when page or rowsPerPage changes
  useEffect(() => {
    getUsers(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage); // Update the page state
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update the number of rows per page
    setPage(0); // Reset to the first page
  };

  if (loading) {
    return <div>Loading....</div>;
  }

  if (users.length > 0) {
    return (
      <div className="restaurantsGridWrapper">
        <UsersDistributionDashboard />
        <div className="button">
          <Button variant="primary" onClick={() => getUsers(page, rowsPerPage)}>
            Refresh
          </Button>
        </div>
        <UserGrid
          users={users}
          selected={selected}
          setSelected={setSelected}
          page={page}
          rowsPerPage={rowsPerPage}
          totalUsers={totalUsers}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    );
  }

  return (
    <div className="restaurantsGridWrapper">
      <div className="emptyPortalResturantsGrid">Empty</div>
    </div>
  );
};

export default UserManager;