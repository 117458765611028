import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { EventsGridView } from "./EventsGridView";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import EventForm from "./EventForm"; // Add this import

const EventsView = () => {
  const [events, setEvents] = useState([]);
  const [selected, setSelected] = React.useState<readonly any[]>([]);
  const { token } = useToken();

  async function getEvents() {
    const url = new URL(`${Base_Url}/events`);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data) {
          setEvents(data.events);
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getEvents();
  }, []);

  const removeEvents = async () => {
    if (!selected || selected.length === 0) return;
    return fetch(`${Base_Url}/events`, {
      method: "DELETE",
      mode: "cors",
      credentials: "same-origin",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        eventIds: selected.map((event) => event.id),
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (!data.success) {
          throw data.errorMessage;
        }
        setEvents(events.filter(event => !selected.includes(event)));
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (events) {
    return (
      <div className="eventsGridWrapper">
        <EventForm />
        <div className="button">
          <Button variant="primary" onClick={getEvents}>
            Refresh
          </Button>
          <Button variant="primary" onClick={removeEvents}>
            Remove
          </Button>
        </div>
        <EventsGridView
          events={events}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    );
  }

  return (
    <div className="eventsGridWrapper">
      <div className="emptyPortalEventsGrid">Empty</div>
    </div>
  );
};

export default EventsView;
