import React, { useEffect, useState } from "react";
import "./PortalRestaurantSearchManager.scss";
import PortalRestaurantSearch from "./PortalRestaurantSearch";
import { VenuesGrid } from "../Venues/VenuesGrid";
import { Button } from "react-bootstrap";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

const PortalRestaurantSearchManager = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selected, setSelected] = React.useState<readonly any[]>([]);
  const [showText, setShowText] = useState("");
  const { token, setToken } = useToken();

  useEffect(() => {
    if (showText != "") {
      const timer = setTimeout(() => {
        setShowText("");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showText]);

  const updateRestaurants = async () => {
    if (!selected || selected.length == 0) return;
    try {
      const response = await fetch(`${Base_Url}/venues`, {
        method: "PUT",
        mode: "cors",
        credentials: "same-origin",
        headers: new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          restaurants: selected,
        }),
      });

      const result = await response.json();
      console.log(result);
      if (result.success) {
        setShowText("Succesfully added restaurant");
      } else {
        setShowText(result.status ?? "Failed to add restaurant");
      }
    } catch (e) {
      setShowText("Error adding restaurant");
    }
  };

  return (
    <div className="restaurant-manager-wrapper">
      <PortalRestaurantSearch
        setResturants={setRestaurants}
        updateRestaurants={updateRestaurants}
      />
      <div>{showText}</div>
      <VenuesGrid
        restaurants={restaurants}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default PortalRestaurantSearchManager;
