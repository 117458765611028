import { Box } from "@mui/material";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Base_Url } from "../../constants/constants";
import "./PortalRestaurantSearchManager.scss";
import useToken from "../Login/useToken";

const PortalRestaurantSearch = ({ setResturants, updateRestaurants }: any) => {
  const [address, setAddress] = useState("");
  const [placeIds, setPlaceIds] = useState("");
  const { token, setToken } = useToken();

  async function getRestaurants(query: any) {
    return fetch(`${Base_Url}/google/venues?${new URLSearchParams(query)}`, {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data.success && data?.restaurants) {
          return data.restaurants;
        }
        throw data.errorMessage;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!address && !placeIds) {
      alert("Please enter either a search location or place IDs.");
      return;
    }

    // Ensure placeIds is an array
    const placeIdsArray = placeIds
      ? placeIds.split(",").map((id) => id.trim())
      : [];

    const restaurants = await getRestaurants({
      address,
      placeIds: placeIdsArray,
    });
    setResturants(restaurants);
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formLocation">
          <div className="input-wrapper">
            <Box className="input">
              <Form.Label>Search Location (Required) </Form.Label>
              <Form.Control
                type="address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Form.Text className="text-muted">
                Make sure you enter a valid address / location
              </Form.Text>
            </Box>

            <Box className="input">
              <Form.Label>Search By Place IDs</Form.Label>
              <Form.Control
                type="text"
                placeholder="Comma separated place IDs"
                value={placeIds}
                onChange={(e) => setPlaceIds(e.target.value)}
              />
              <Form.Text className="text-muted">
                Enter comma separated place IDs
              </Form.Text>
            </Box>
          </div>

          <div className="button">
            <Button
              variant="primary"
              type="submit"
              disabled={!address && !placeIds}
            >
              Search
            </Button>
          </div>

          <div className="button">
            <Button variant="primary" onClick={updateRestaurants}>
              Add Selected Restaurants
            </Button>
          </div>
        </Form.Group>
      </Form>
    </Box>
  );
};

export default PortalRestaurantSearch;
