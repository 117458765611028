import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { ReportDataResponse, ReportsGrid } from "./ReportsGrid";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

const ReportManager = () => {
  const [reports, setReports] = useState<ReportDataResponse>();
  const [selected, setSelected] = React.useState<readonly any[]>([]);
  const { token, setToken } = useToken();

  async function getReports() {
    const url = new URL(`${Base_Url}/users/reports`);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data) {
          setReports(data.reports);
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getReports();
  }, []);
  //   }, [token]);

  if (reports && Object.keys(reports).length > 0) {
    return (
      <div className="reportManagerWrapper">
        <div className="button">
          <Button variant="primary" onClick={getReports}>
            Refresh
          </Button>
        </div>
        <ReportsGrid
          reports={reports}
          selected={selected}
          setSelected={setSelected}
        ></ReportsGrid>
      </div>
    );
  }

  return (
    <div className="reportManagerWrapper">
      <div className="emptyPortalResturantsGrid">Empty</div>
    </div>
  );
};

export default ReportManager;
