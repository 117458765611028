import React, { createContext, useContext, useState } from 'react';

interface UserInfoViewData {
    user: any;
    setUser: (user: any) => any
}

const defaultValue: UserInfoViewData = {
    user: null, 
    setUser: () => {},
}

const UserInfoViewContext = createContext<UserInfoViewData>(defaultValue);

// Context provider component
export const UserInfoViewProvider = ({ children }: any) => {
  const [user, setUser] = useState(null); // Hold the data in state

  // The value that will be supplied to any descendants of this provider
  const value = { user, setUser };

  return <UserInfoViewContext.Provider value={value}>{children}</UserInfoViewContext.Provider>;
};

// Custom hook to use the context
export const useUserInfoViewData = () => useContext(UserInfoViewContext);