import React, { useState, useEffect } from "react";
import { Base_Url } from "../../constants/constants"; // Assuming you have a base URL constant
import useToken from "../Login/useToken"; // Assuming you have a custom hook for token handling

type UserMetrics = {
  numUsers: number;
  numOnboarded: number;
  numLikes: number;
  numDislikes: number;
  numMatches: number;
  numMessages: number;
  numMale: number;
  numFemale: number;
  numNonbinary: number;
  numTransMan: number;
  numTransWomen: number;
  numGenderQueer: number;
};

export const UsersDistributionDashboard = () => {
  const [metrics, setMetrics] = useState<UserMetrics | null>(null);
  const { token } = useToken(); // Assuming token is needed for authenticated requests
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMetrics = async () => {
      setLoading(true);
      try {
        const url = `${Base_Url}/users/metrics`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: token, // Add token if necessary
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data: UserMetrics = await response.json();
          setMetrics(data);
        } else {
          throw new Error("Failed to fetch metrics");
        }
      } catch (error) {
        console.error(error);
        setMetrics(null);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [token]);

  if (loading) {
    return <div>Loading metrics...</div>;
  }

  if (!metrics) {
    return <div>Failed to load metrics</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        marginBottom: "20px",
        justifyContent: "space-around",
      }}
    >
      <div>
        <strong>Num Users:</strong>
        <div>{metrics.numUsers}</div>
      </div>
      <div>
        <strong>Gender Distribution:</strong>
        <div>Male: {metrics.numMale}</div>
        <div>Female: {metrics.numFemale}</div>
        <div>Trans Man: {metrics.numTransMan}</div>
        <div>Trans Women: {metrics.numTransWomen}</div>
        <div>Gender Queer: {metrics.numGenderQueer}</div>
        <div>Non-Binary: {metrics.numNonbinary}</div>
      </div>
      <div>
        <strong>Like/Dislike Info</strong>
        <div>Likes: {metrics.numLikes}</div>
        <div>Dislikes: {metrics.numDislikes}</div>
        <div>Messages: {metrics.numMessages}</div>
        <div>Matches: {metrics.numMatches}</div>
      </div>
    </div>
  );
};