import React, { useEffect, useState } from "react";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

export const UserActivesInfo = ({
  activeVenues,
  activeEvents,
}: {
  activeVenues: string[];
  activeEvents: string[];
}) => {
  const { token, setToken } = useToken();
  const [venueData, setVenueData] = useState<{ id: string; name: string }[]>(
    []
  );
  const [eventData, setEventData] = useState<{ id: string; name: string }[]>(
    []
  );

  async function getActivityData(venues: string[], events: string[]) {
    const url = new URL(`${Base_Url}/activities`);

    if (venues?.length > 0) {
      url.searchParams.append("venueIds", venues.join(","));
    }
    if (events?.length > 0) {
      url.searchParams.append("eventIds", events.join(","));
    }

    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };

    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .catch((error) => {
        console.error("Error fetching activity data:", error);
        return null;
      });
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getActivityData(activeVenues, activeEvents);
      if (data) {
        setVenueData(data.venues || []); // Assuming the API returns `venues`
        setEventData(data.events || []); // Assuming the API returns `events`
      }
    }

    fetchData();
  }, [activeVenues, activeEvents]);

  return (
    <div>
      {/* Active Venues Section */}
      <div>
        <b>Active Venues ({venueData?.length})</b>
        <ul>
          {venueData?.map((venue) => (
            <li key={venue.id}>
              {venue.name} (ID: {venue.id})
            </li>
          ))}
        </ul>
      </div>

      {/* Active Events Section */}
      <div>
        <b>Active Events ({eventData?.length})</b>
        <ul>
          {eventData?.map((event) => (
            <li key={event.id}>
              {event.name} (ID: {event.id})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
