import React, { useState, useEffect } from "react";
import { Button, Form, Modal, ListGroup } from "react-bootstrap";
import useToken from "../Login/useToken";
import { Base_Url } from "../../constants/constants";
import NotificationsUserView from "./NotificationsUserView";

interface Notification {
  title: string;
  body: string;
}

const NotificationsView = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [sentNotifications, setSentNotifications] = useState<Notification[]>([]);
  const { token } = useToken();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false); // New state for result modal
  const [resultMessage, setResultMessage] = useState(""); // New state for result message

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const url = new URL(`${Base_Url}/users`);
      url.searchParams.append("page", (page + 1).toString());
      url.searchParams.append("limit", rowsPerPage.toString());

      const options = {
        method: "GET",
        headers: new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        }),
      };

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
          setTotalUsers(data.total);
        } else {
          throw new Error("Failed to fetch users");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token, page, rowsPerPage]);

  const handleSend = () => {
    setShowSendModal(true);
  };

  const confirmSend = () => {
    fetch(`${Base_Url}/notifications`, {
      method: "POST",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ userIds: selectedUserIds, title, body }),
    })
      .then(async (response) => {
        if (response.ok) {
          setResultMessage("Notification sent successfully");
          setSentNotifications([...sentNotifications, { title, body }]);
        } else {
          const errorMessage = await response.text(); // Await the response text
          setResultMessage(`Notification failed to send: ${errorMessage}`);
        }
        setShowResultModal(true);
      })
      .catch((error) => {
        console.error(error);
        setResultMessage("Notification failed to send");
        setShowResultModal(true);
      })
      .finally(() => setShowSendModal(false));
  };

  const handleSendAll = () => {
    setShowModal(true);
  };

  const confirmSendAll = () => {
    fetch(`${Base_Url}/notifications`, {
      method: "POST",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ sendToAll: true, title, body }),
    })
      .then(async (response) => {
        if (response.ok) {
          setResultMessage("Notification sent successfully");
          setSentNotifications([...sentNotifications, { title, body }]);
        } else {
          const errorMessage = await response.text(); // Await the response text
          setResultMessage(`Notification failed to send: ${errorMessage}`);
        }
        setShowResultModal(true);
      })
      .catch((error) => {
        console.error(error);
        setResultMessage("Notification failed to send");
        setShowResultModal(true);
      })
      .finally(() => setShowModal(false));
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserIdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ids = e.target.value.split(',').map(id => id.trim());
    setSelectedUserIds(ids);
    setSelectedUsers(ids);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Form.Group controlId="notificationTitle">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Title..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="notificationBody">
        <Form.Label>Body</Form.Label>
        <Form.Control
          type="text"
          placeholder="Body..."
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" onClick={handleSend}>
        Send
      </Button>
      <Button variant="secondary" onClick={handleSendAll}>
        Send All
      </Button>
      <Form.Group controlId="selectedUserIds">
        <Form.Label>Selected User IDs</Form.Label>
        <Form.Control
          type="text"
          placeholder="Comma-separated user IDs..."
          value={selectedUserIds.join(", ")}
          onChange={handleUserIdsChange}
        />
      </Form.Group>
      <NotificationsUserView
        users={users}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        setSelectedUserIds={setSelectedUserIds}
        page={page}
        rowsPerPage={rowsPerPage}
        totalUsers={totalUsers}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <Modal show={showSendModal} onHide={() => setShowSendModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Send</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send this message to the selected users?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSendModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmSend}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Send All</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send this message to all users?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmSendAll}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resultMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowResultModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <h3>Previously Sent Notifications</h3>
      <ListGroup>
        {sentNotifications.map((notification, index) => (
          <ListGroup.Item key={index}>
            <strong>Title:</strong> {notification.title} <br />
            <strong>Body:</strong> {notification.body}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default NotificationsView;