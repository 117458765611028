import React, { useState } from "react";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { Button } from "react-bootstrap";
import './EventForm.scss'; // Import the SCSS file
import { toDate, formatInTimeZone } from 'date-fns-tz';
import { parse } from 'date-fns';
// Define an interface for the error messages
interface FormErrors {
  eventSource?: string;
  name?: string;
  date?: string;
  latitude?: string;
  longitude?: string;
  venue?: string;
  neighborhood?: string;
  price?: string;
  summary?: string;
  ticketLink?: string;
  ageLimit?: string;
  alcohol?: string;
  photos?: string;
}

interface FormValues {
  eventSource: string;
  name: string;
  date: string;
  location?: {
    latitude: string;
    longitude: string;
  };
  description?: string;
  venue?: string;
  neighborhood?: string;
  price?: string;
  summary?: string;
  ticketLink?: string;
  ageLimit?: string;
  alcohol?: string;
  photos?: string;
  artists?: string;
}

const easternTimeZone = 'America/New_York';
const utcTimeZone = 'UTC';

const EventForm = () => {
  const { token } = useToken();
  const [formValues, setFormValues] = useState<FormValues>({
    eventSource: "",
    name: "",
    date: "",
    // Initialize other properties as needed
    location: {
      latitude: "",
      longitude: "",
    },
    description: "",
    // ... any other initial values
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const validate = () => {
    const newErrors: FormErrors = {};
    if (!formValues.eventSource) newErrors.eventSource = "Required";
    if (!formValues.name) newErrors.name = "Required";
    if (!formValues.date) newErrors.date = "Required";
    if (!formValues.location?.latitude) newErrors.latitude = "Required";
    if (!formValues.location?.longitude) newErrors.longitude = "Required";
    if (!formValues.venue) newErrors.venue = "Required";
    if (!formValues.neighborhood) newErrors.neighborhood = "Required";
    if (!formValues.price) newErrors.price = "Required";
    if (!formValues.summary) newErrors.summary = "Required";
    if (!formValues.ticketLink) {
      newErrors.ticketLink = "Required";
    } else if (!/^https?:\/\/.+\..+/.test(formValues.ticketLink)) {
      newErrors.ticketLink = "Invalid URL";
    }
    if (!formValues.ageLimit) newErrors.ageLimit = "Required";
    if (!formValues.alcohol) newErrors.alcohol = "Required";
    if (!formValues.photos) newErrors.photos = "Required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    // Convert date to UTC format if the field is 'date'
    // const newValue = name === "date" ? new Date(value).toISOString() : value;
    const newValue = value;
    setFormValues({
      ...formValues,
      [name]: newValue,
    });
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      location: {
        ...prevValues.location,
        [name]: value || "", // Ensure value is a string
        latitude:
          name === "latitude"
            ? value || ""
            : prevValues.location?.latitude || "", // Update latitude if the field is being changed
        longitude:
          name === "longitude"
            ? value || ""
            : prevValues.location?.longitude || "", // Update longitude if the field is being changed
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;

    console.log(formValues.date);

    // Parse the date string as if it's in Eastern Time
    const easternDate = toDate(formValues.date, { timeZone: easternTimeZone });
    // Format the Eastern Time date to UTC
    const formattedDate = formatInTimeZone(easternDate, utcTimeZone, "yyyy-MM-dd'T'HH:mm:ss'Z'");
    console.log(formattedDate);

    const updatedFormValues = {
      ...formValues,
      date: formattedDate,
    };

    try {
      const response = await fetch(`${Base_Url}/events/event`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...updatedFormValues,
          artists: formValues.artists
            ?.split(",")
            .map((artist) => artist.trim()),
          photos: formValues.photos?.split(",").map((photo) => photo.trim()),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add event");
      }

      const data = await response.json();
      console.log("Event added:", data);
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal" error={!!errors.name}>
        <TextField
          id="name"
          name="name"
          label="Name"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.name}
          helperText={errors.name}
        />
      </FormControl>

      <FormControl
        style={{ width: "300px" }}
        margin="normal"
        error={!!errors.eventSource}
      >
        <InputLabel id="eventSource-label">Event Source</InputLabel>
        <Select
          labelId="eventSource-label"
          id="eventSource"
          name="eventSource"
          onChange={handleChange as (event: SelectChangeEvent<string>) => void}
          value={formValues.eventSource}
          label="Event Source"
        >
          <MenuItem value="" className="flex-menu-item">
            <em>Select</em>
          </MenuItem>
          <MenuItem value="Grid" className="flex-menu-item">Grid</MenuItem>
          <MenuItem value="Sofar" className="flex-menu-item">Sofar</MenuItem>
        </Select>
        {errors.eventSource && (
          <FormHelperText>{errors.eventSource}</FormHelperText>
        )}
      </FormControl>

      <FormControl margin="normal" error={!!errors.date}>
        <TextField
          id="date"
          name="date"
          label="Date and Time"
          type="datetime-local"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.date}
          helperText={errors.date}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>

      <FormControl margin="normal" error={!!errors.latitude}>
        <TextField
          id="latitude"
          name="latitude"
          label="Latitude"
          type="text"
          onChange={handleLocationChange}
          value={formValues.location?.latitude}
          helperText={errors.latitude}
        />
      </FormControl>

      <FormControl margin="normal" error={!!errors.longitude}>
        <TextField
          id="longitude"
          name="longitude"
          label="Longitude"
          type="text"
          onChange={handleLocationChange}
          value={formValues.location?.longitude}
          helperText={errors.longitude}
        />
      </FormControl>

      <FormControl margin="normal" error={!!errors.venue}>
        <TextField
          id="venue"
          name="venue"
          label="Venue"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.venue}
          helperText={errors.venue}
        />
      </FormControl>

      <FormControl
        style={{ width: "300px" }}
        margin="normal"
        error={!!errors.neighborhood}
      >
        <InputLabel id="neighborhood-label">Neighborhood</InputLabel>
        <Select
          labelId="neighborhood-label"
          id="neighborhood"
          name="neighborhood"
          onChange={handleChange as (event: SelectChangeEvent<string>) => void}
          value={formValues.neighborhood || ""}
          label="Neighborhood"
        >
          <MenuItem value="" className="flex-menu-item">
            <em>Select Neighborhood</em>
          </MenuItem>
          <MenuItem value="West Village" className="flex-menu-item">West Village</MenuItem>
          <MenuItem value="East Village" className="flex-menu-item">East Village</MenuItem>
        </Select>
        {errors.neighborhood && (
          <FormHelperText>{errors.neighborhood}</FormHelperText>
        )}
      </FormControl>

      <FormControl margin="normal" error={!!errors.price}>
        <TextField
          id="price"
          name="price"
          label="Price"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.price}
          helperText={errors.price}
        />
      </FormControl>

      <FormControl fullWidth margin="normal" error={!!errors.summary}>
        <TextField
          id="summary"
          name="summary"
          label="Summary"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.summary}
          helperText={errors.summary}
        />
      </FormControl>

      <FormControl margin="normal" error={!!errors.ticketLink}>
        <TextField
          id="ticketLink"
          name="ticketLink"
          label="Ticket Link"
          type="url"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.ticketLink}
          helperText={errors.ticketLink}
        />
      </FormControl>

      <FormControl margin="normal" error={!!errors.ageLimit}>
        <TextField
          id="ageLimit"
          name="ageLimit"
          label="Age Limit"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.ageLimit}
          helperText={errors.ageLimit}
        />
      </FormControl>

      <FormControl
        style={{ width: "300px" }}
        margin="normal"
        error={!!errors.alcohol}
      >
        <InputLabel id="alcohol-label">Alcohol</InputLabel>
        <Select
          labelId="alcohol-label"
          id="alcohol"
          name="alcohol"
          onChange={handleChange as (event: SelectChangeEvent<string>) => void}
          value={formValues.alcohol || ""}
          label="Alcohol"
        >
          <MenuItem value="" className="flex-menu-item">
            <em>Select</em>
          </MenuItem>
          <MenuItem value="purchase" className="flex-menu-item">Purchase</MenuItem>
          <MenuItem value="byob" className="flex-menu-item">BYOB</MenuItem>
          <MenuItem value="none" className="flex-menu-item">None</MenuItem>
        </Select>
        {errors.alcohol && <FormHelperText>{errors.alcohol}</FormHelperText>}
      </FormControl>

      <FormControl margin="normal" error={!!errors.photos}>
        <TextField
          id="photos"
          name="photos"
          label="Photos"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.photos}
          helperText={errors.photos}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          id="artists"
          name="artists"
          label="Artists"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.artists}
        />
      </FormControl>

      <div style={{ textAlign: "right" }}>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default EventForm;
