export const capitalizeWord = (str: string) => {
  if (!str) return "";
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const concatString = (str: string, n: number) => {
  if (str.length < n) {
    return str;
  } else {
    return str.substring(0, n);
  }
};
