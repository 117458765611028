import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  TableHead,
} from "@mui/material";

interface NotificationsUserViewProps {
  users: any[];
  selectedUsers: string[];
  setSelectedUsers: (selected: string[]) => void;
  setSelectedUserIds: (ids: string[]) => void; // Add this line
  page: number;
  rowsPerPage: number;
  totalUsers: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const NotificationsUserView = (props: NotificationsUserViewProps) => {
  const {
    users,
    selectedUsers,
    setSelectedUsers,
    setSelectedUserIds,
    page,
    rowsPerPage,
    totalUsers,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users.map((n: any) => n.id);
      setSelectedUsers(newSelected);
      setSelectedUserIds(newSelected); // Ensure it's an array
      return;
    }
    setSelectedUsers([]);
    setSelectedUserIds([]); // Pass an empty array
  };

  const handleClick = (event: React.MouseEvent<unknown>, userId: string) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelected);
    setSelectedUserIds(newSelected);
  };

  const isSelected = (userId: string) => selectedUsers.indexOf(userId) !== -1;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="user table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={
                  selectedUsers.length > 0 && selectedUsers.length < users.length
                }
                checked={users.length > 0 && selectedUsers.length === users.length}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all users" }}
              />
            </TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: any) => (
            <TableRow
              hover
              role="checkbox"
              key={user.id}
              selected={isSelected(user.id)}
              onClick={(event) => handleClick(event, user.id)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isSelected(user.id)}
                  inputProps={{
                    "aria-labelledby": `user-checkbox-${user.id}`,
                  }}
                />
              </TableCell>
              <TableCell>{user.firstName} {user.lastName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default NotificationsUserView;
