import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, TableSortLabel, Checkbox } from "@mui/material";

interface RestaurantsGridProps {
  restaurants: any[];
  selected: readonly any[];
  setSelected: any;
}

export const VenuesGrid = (props: RestaurantsGridProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selected, setSelected } = props;
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");

  const restaurants = props.restaurants || [];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - restaurants.length) : 0;

  const handleRestaurantRequestSort = () => {
    const isAsc = orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    sortRestaurants(isAsc ? "desc" : "asc");
  };

  const sortRestaurants = (direction: "asc" | "desc") => {
    return restaurants.sort((a, b) => {
      if (direction === "asc") return a.users?.length - b.users?.length;
      else return b.users?.length - a.users?.length;
    });
  };

  const sortedRestaurants = React.useMemo(() => {
    return sortRestaurants(orderDirection);
  }, [restaurants, orderDirection]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = restaurants.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, restaurant: any) => {
    const selectedIndex = selected.indexOf(restaurant);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, restaurant);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (restaurant: any) => selected.indexOf(restaurant) !== -1;

  return (
    <div className="restaurantsGridWrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow key="header">
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selected.length > 0 && selected.length < restaurants.length
                  }
                  checked={
                    restaurants.length > 0 &&
                    selected.length === restaurants.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Neighborhood</TableCell>
              <TableCell>Location</TableCell>
              <TableCell align="left">Rating</TableCell>
              <TableCell align="left">Reviews</TableCell>
              <TableCell align="left" sortDirection={orderDirection}>
                <TableSortLabel
                  active={true}
                  direction={orderDirection}
                  onClick={handleRestaurantRequestSort}
                >
                  Num Users
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? restaurants.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : restaurants
            ).map((restaurant: any, index: any) => {
              const isItemSelected = isSelected(restaurant);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={restaurant.id}
                  role="checkbox"
                  onClick={(event) => handleClick(event, restaurant)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <a href={restaurant.url} target="_blank">
                      {restaurant.name}
                    </a>
                  </TableCell>
                  <TableCell align="left">{restaurant.address}</TableCell>
                  <TableCell align="left">{restaurant.neighborhood}</TableCell>
                  <TableCell align="left">{JSON.stringify(restaurant.location)}</TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {restaurant.rating}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {restaurant.ratingCount}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {restaurant.users?.length}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  25,
                  50,
                  100,
                  { label: "All", value: -1 },
                ]}
                colSpan={3}
                count={restaurants.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
